import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      name: "home",
      component: (resolve) => require(["@/views/Home"], resolve),
    },
    {
      path: "/news",
      name: "news",
      component: (resolve) => require(["@/views/News"], resolve),
    },
    {
      path: "/sass",
      name: "sass",
      component: (resolve) => require(["@/views/Sass"], resolve),
    },
    {
      path: "/draft",
      name: "draft",
      component: (resolve) => require(["@/views/Draft"], resolve),
    },
    {
      path: "/about",
      name: "about",
      component: (resolve) => require(["@/views/About"], resolve),
    },
    {
      path: "/blockchain",
      name: "blockchain",
      component: (resolve) => require(["@/views/Blockchain"], resolve),
    },

    {
      path: "/empowerment",
      name: "empowerment",
      component: (resolve) => require(["@/views/Empowerment"], resolve),
    },
    {
      path: "/legal",
      name: "legal",
      component: (resolve) => require(["@/views/Legal"], resolve),
    },
  ],
});
