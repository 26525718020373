<template>
  <div id="app">
    <div class="pre">
      <div class="flex">
        <div class="flex modal-us relative item-center" v-if="showModal">
          <div class="absolute modal-right">
            <img src="@/assets/images/modal-right.png" />
          </div>

          <img src="@/assets/images/footer-code1.png" width="100" height="100" />
          <div>
            <div class="font-14">联系我们</div>
            <div class="font-12 mt-5">商务合作/技术支持</div>
            <!-- <div class="font-12">service@shanshoufu.com</div> -->
            <div class="font-16 mt-5">400-063-6300</div>
          </div>
        </div>
        <div class="pre-sales" @mouseenter="onMousedown">
          <img src="@/assets/images/service.png" width="100%" />
          <div class="text">售 前 咨 询</div>
          <div></div>
          <img src="@/assets/images/icon-up.png" alt="" @click="goTop" />
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showModal: false,
    };
  },
  components: {},
  methods: {
    goTop() {
      window.scrollTo(0, 0); // 将页面滚动到顶部

    },
    onMousedown() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    onMouseleave() {
      this.showModal = false;
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        const hash = this.$route.hash;
        if (hash) {
          const position = document.getElementById(hash.substring(1));
          if (position) {
            position.scrollIntoView();
          }
        }
      });

    },
  },
};
</script>

<style lang="less">
.pre {
  position: fixed;
  right: 30px;
  top: 45%;
  z-index: 99999;

  .pre-sales {
    cursor: pointer;
    width: 70px;
    height: 214px;
    background: #2468f1;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.16);
    border-radius: 4px 4px 4px 4px;
    text-align: center;

    img {
      width: 30px;
      height: 30px;
      margin: 15px 0;
    }

    .text {
      display: flex;
      margin-top: 5px;
      align-items: center;
      writing-mode: vertical-rl;
      /*从右向左竖排*/
      width: 70px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      // width: 100%;
    }
  }

  .modal-right {
    height: 100%;
    display: flex;
    align-items: center;
    right: -29px;

    img {
      width: 20px;
      height: 20px;
      opacity: 0.3;
    }
  }

  .modal-us {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 15px;
    height: 130px;
    margin-right: 15px;
    border-radius: 10px;

    img {
      margin-right: 15px;
    }
  }
}
</style>
